// Keep this for hot-loading to work properly
if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(console.error);
}

import "jquery";
import "magnific-popup";
// import "zenscroll";
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, Keyboard } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/keyboard';
import "lazysizes";
import "lazysizes/plugins/unload/ls.unload";

// import "./gravityforms/collapsible-sections.js";
import "./gravityforms/debugging.js";
import "./LowPolyGenerator.js";


(function($) {


  var interested = [];
  $("#sendMessage").click(function () {
    $("input[name='interest']:checked").each(function () {
      interested.push($(this).val());
    });
    $("#interested_in").val(interested);
  });



//     $.fn.randomCirclesSVG = function(options) {
//     const defaults = {
//         baseColor: null,
//         minCircles: 10,
//         maxCircles: 30
//     };
//     const settings = $.extend({}, defaults, options);

//     return this.each(function() {
//         const $container = $(this);
//         const containerId = $container.attr('id') || 'svg-' + Math.random().toString(36).substr(2, 9);
//         $container.attr('id', containerId);

//         const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
//         svg.setAttribute("viewBox", "0 0 100 100");
//         svg.style.width = "100%";
//         svg.style.height = "100%";

//         const getRandomColor = () => '#' + Math.floor(Math.random()*16777215).toString(16).padStart(6, '0');
        
//         const getMutedColor = (color) => {
//             const rgb = parseInt(color.slice(1), 16);
//             const r = (rgb >> 16) & 255;
//             const g = (rgb >> 8) & 255;
//             const b = rgb & 255;
//             return `rgb(${r * 0.7 + 64}, ${g * 0.7 + 64}, ${b * 0.7 + 64})`;
//         };

//         const generateGradientColors = () => {
//             const color1 = settings.baseColor || getRandomColor();
//             const color2 = getMutedColor(color1);
//             return [getMutedColor(color1), getMutedColor(color2)];
//         };

//         const gradientColors = generateGradientColors();

//         // Create definitions
//         const defs = document.createElementNS("http://www.w3.org/2000/svg", "defs");
        
//         // Background gradient
//         const bgGradient = document.createElementNS("http://www.w3.org/2000/svg", "linearGradient");
//         bgGradient.setAttribute("id", `bgGradient-${containerId}`);
//         bgGradient.innerHTML = `
//             <stop offset="0%" stop-color="${gradientColors[0]}" />
//             <stop offset="100%" stop-color="${gradientColors[1]}" />
//         `;
//         defs.appendChild(bgGradient);

//         // Circle gradient
//         const circleGradient = document.createElementNS("http://www.w3.org/2000/svg", "linearGradient");
//         circleGradient.setAttribute("id", `circleGradient-${containerId}`);
//         circleGradient.innerHTML = `
//             <stop offset="0%" stop-color="${gradientColors[1]}" stop-opacity="0.5" />
//             <stop offset="100%" stop-color="${gradientColors[0]}" stop-opacity="0.5" />
//         `;
//         defs.appendChild(circleGradient);

//         svg.appendChild(defs);

//         // Background rectangle
//         const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
//         rect.setAttribute("width", "100%");
//         rect.setAttribute("height", "100%");
//         rect.setAttribute("fill", `url(#bgGradient-${containerId})`);
//         svg.appendChild(rect);

//         // Generate circles
//         const numCircles = Math.floor(Math.random() * (settings.maxCircles - settings.minCircles + 1)) + settings.minCircles;
//         const circles = [];
//         for (let i = 0; i < numCircles; i++) {
//             const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
//             circle.setAttribute("cx", Math.random() * 110 - 5);
//             circle.setAttribute("cy", Math.random() * 110 - 5);
//             circle.setAttribute("r", Math.random() * 10 + 3);
//             circle.setAttribute("fill", `url(#circleGradient-${containerId})`);
//             circle.style.transition = "all 3s ease-in-out";
//             circles.push({
//                 element: circle,
//                 dx: (Math.random() - 0.5) * 0.2,
//                 dy: (Math.random() - 0.5) * 0.2,
//                 dr: (Math.random() - 0.5) * 0.1
//             });
//             svg.appendChild(circle);
//         }

//         $container.append(svg);

//         let animationId;
        
//         function animate() {
//             circles.forEach(circle => {
//                 let cx = parseFloat(circle.element.getAttribute("cx")) + circle.dx;
//                 let cy = parseFloat(circle.element.getAttribute("cy")) + circle.dy;
//                 let r = parseFloat(circle.element.getAttribute("r")) + circle.dr;

//                 if (cx < -10 || cx > 110) circle.dx *= -1;
//                 if (cy < -10 || cy > 110) circle.dy *= -1;
//                 if (r < 1 || r > 15) circle.dr *= -1;

//                 circle.element.setAttribute("cx", cx);
//                 circle.element.setAttribute("cy", cy);
//                 circle.element.setAttribute("r", r);
//             });

//             animationId = requestAnimationFrame(animate);
//         }

//         $container.on("mouseenter", function() {
//             animate();
//         }).on("mouseleave", function() {
//             cancelAnimationFrame(animationId);
//             circles.forEach(circle => {
//                 circle.element.style.transition = "all 1s ease-out";
//                 circle.element.setAttribute("cx", Math.random() * 110 - 5);
//                 circle.element.setAttribute("cy", Math.random() * 110 - 5);
//                 circle.element.setAttribute("r", Math.random() * 10 + 3);
//             });
//         });
//     });
// };

//     $(document).ready(function() {
//     $(".default-svg").randomCirclesSVG({
//         baseColor: "#ff0000" // Optional: Remove this line for random colors
//     });
// });

const $testimonialSwiper = $('.buckup-testimonials');

if ($testimonialSwiper.length) {
  const $swiperContainer = $testimonialSwiper.find('.swiper-container');
  const dataSlides = parseInt($swiperContainer.data('slides')) || 4;
  const slidesFor1024 = Math.min(dataSlides, 5);
  const slidesFor768 = dataSlides > 3 ? 3 : dataSlides;

  new Swiper('.buckup-testimonials .swiper-container', {
    modules: [Navigation, Pagination, Autoplay, Keyboard],
    slidesPerView: 'auto',
    centeredSlides: true,
    initialSlide: 2,
    speed: 1500,
    autoplay: false,
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      1024: {
        slidesPerView: slidesFor1024,
      },
      768: {
        slidesPerView: slidesFor768,
      },
      320: {
        slidesPerView: 1,
      },
    },
  });
}




const $clientSwiper = $('#clients');

if ($clientSwiper.length) {
  new Swiper('#clients .swiper-container', {
    modules: [Navigation, Pagination, Autoplay, Keyboard], // Make sure to list the modules
    slidesPerView: 'auto',
    centeredSlides: true,
    initialSlide: 2,
    speed: 1500,
    loop: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      1024: {
        slidesPerView: 4,
      },
      768: {
        slidesPerView: 3,
      },
      640: {
        slidesPerView: 2,
      },
      320: {
        slidesPerView: 1,
      },
    },
  });
}


const $projectSwiper = $('.project-container');

if ($projectSwiper.length) {
  new Swiper('.project-container', {
    modules: [Navigation, Pagination, Autoplay, Keyboard], // Include the Lazy module here
    direction: 'vertical',
    lazy: true,
    speed: 1500,
    autoplay: {
      delay: 7000,
      // delay: 3500,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      // renderBullet: function (index, className) {
      //   var year = document
      //     .querySelectorAll(".swiper-slide")
      //     [index].getAttribute("data-year");
      //   return '<span class="' + className + '">' + year + "</span>";
      // },
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      768: {
        direction: 'vertical',
      }
    }
  });
}
















// $(".popup-modal").magnificPopup({
//   type: "inline",
//   preloader: false,
//   modal: false,
//   midClick: true,
//   callbacks: {
//     beforeOpen: function () {
//       this.st.image.markup = this.st.image.markup.replace(
//         "mfp-figure",
//         "mfp-figure animated " + this.st.el.attr("data-effect")
//       );
//     },
//   },
// });

// $("a.learn-more").click(function () {
//   var interest = $(this).data("interest");
//   var input = $("#contact-us input#" + interest);

//   input.prop("checked", true);
//   input.parent().attr("data-interested", "true");
// });

// $('input[name="interest"]').click(function () {
//   $(this).parent().attr("data-interested", this.checked);
// });

// document.addEventListener("scroll", () => {
//   document.documentElement.dataset.scroll = window.scrollY;
// });

// // // open popup on timeout
// // var $popupTimeOut = 0; // set timeout length
// // $(document).ready(function () {
// //   setTimeout(function() {
// //    if ($('#designing-more').length) {
// //      $.magnificPopup.open({
// //       items: {
// //         src: '#designing-more'
// //       },
// //         type: 'inline'
// //       });
// //      }
// //    }, $popupTimeOut);
// // });

// // $('main section').each(function () {
// //     var $viewport = $(this);
// //
// //     $viewport
// //       .isInViewport({ tolerance: -500, viewport: $viewport })
// //       .addClass('animate');
// // });

// // var headerBottom = $('#header').offset().top + $('#header').height();
// //
// // $(window).on('scroll',function(){
// //
// //     var stop = Math.round($(window).scrollTop());
// //
// //     if (stop > headerBottom) {
// //         $('main').addClass('sticky');
// //     } else {
// //         $('main').removeClass('sticky');
// //     }
// //
// // });

// // $("#header").hover(function() {
// //   $(this).removeClass('finished');
// // }, function() {
// //   $(this).addClass('finished');
// // });
// //
// // $("#master").hover(function() {
// //   $("#header").addClass('paused');
// // }, function() {
// //   $("#header").removeClass('paused');
// // });

// var didScroll = false;
// window.onscroll = doOnScroll;
// var navEl = $("#nav");
// var navTop = navEl.offsetTop;

// setInterval(function () {
//   if (didScroll) {
//     didScroll = false;
//   }
// }, 100);

// function doOnScroll() {
//   didScroll = true;

//   if (window.pageYOffset > navTop) {
//     $(navEl).addClass("sticky");
//   } else {
//     $(navEl).removeClass("sticky");
//   }

//   var windowTop = $(window).scrollTop(),
//     windowHeight = $(window).height();
//   $(".level").css("width", "0px");
//   $(".skill-group").each(function () {
//     var skillsTop = $(this).offset().top;
//     if (windowTop + windowHeight > skillsTop) {
//       $(".level", this).each(function () {
//         var percentage = $(this).attr("data-percent");
//         $(this).animate({ width: percentage }, 2000);
//       });
//     }
//   });
// } // doOnScroll

// $("#nav a").click(function () {
//   var navHeight = $("#nav").height();

//   if ($(this).parents("#nav").hasClass("sticky")) {
//     $("a.anchor").css("top", -navHeight);
//   } else {
//     $("a.anchor").css("top", -navHeight - navHeight);
//   }
// });

// $(".input-group .input").focusout(function () {
//   var text_val = $(this).val();

//   if (text_val === "") {
//     $(this).removeClass("has-value");
//   } else {
//     $(this).addClass("has-value");
//   }
// });

// $(".browserupgrade").click(function () {
//   $(this).hide();
// });

// function anchorLinkHandler(e) {
//   const distanceToTop = (el) => Math.floor(el.getBoundingClientRect().top);

//   e.preventDefault();
//   const targetID = this.getAttribute("href");
//   const targetAnchor = document.querySelector(targetID);
//   if (!targetAnchor) return;
//   const originalTop = distanceToTop(targetAnchor);

//   window.scrollBy({ top: originalTop, left: 0, behavior: "smooth" });

//   const checkIfDone = setInterval(function () {
//     const atBottom =
//       window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
//     if (distanceToTop(targetAnchor) === 0 || atBottom) {
//       targetAnchor.tabIndex = "-1";
//       targetAnchor.focus();
//       window.history.pushState("", "", targetID);
//       clearInterval(checkIfDone);
//     }
//   }, 100);
// }

// const linksToAnchors = document.querySelectorAll('a[href^="#"]');

// linksToAnchors.forEach((each) => (each.onclick = anchorLinkHandler));


})(jQuery);