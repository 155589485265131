class LowPolyGenerator {
  constructor(canvasClass, parentClass, numPoints = 50, numPolygons = 100) {
    this.canvasClass = canvasClass;
    this.parentClass = parentClass;
    this.numPoints = numPoints;
    this.numPolygons = numPolygons;
    this.canvases = document.getElementsByClassName(this.canvasClass);
    this.parents = document.getElementsByClassName(this.parentClass);
    this.canvasStates = new Map();
    this.setupEventListeners();
  }

  setupEventListeners() {
    for (let parent of this.parents) {
      parent.addEventListener('mouseenter', () => {
        const canvas = parent.querySelector(`.${this.canvasClass}`);
        if (canvas) this.startAnimation(canvas);
      });
      parent.addEventListener('mouseleave', () => {
        const canvas = parent.querySelector(`.${this.canvasClass}`);
        if (canvas) this.stopAnimation(canvas);
      });
    }
  }

  generatePoints(width, height) {
    return Array.from({ length: this.numPoints }, () => ({
      x: Math.random() * width,
      y: Math.random() * height
    }));
  }

  drawPolygon(ctx, points, color) {
    ctx.beginPath();
    ctx.moveTo(points[0].x, points[0].y);
    ctx.lineTo(points[1].x, points[1].y);
    ctx.lineTo(points[2].x, points[2].y);
    ctx.closePath();
    ctx.fillStyle = color;
    ctx.fill();
  }

  generateImage(canvas, initialGeneration = true) {
    const ctx = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;
    let state = this.canvasStates.get(canvas);

    if (initialGeneration || !state) {
      const hue = Math.floor(Math.random() * 360);
      state = {
        hue,
        backgroundColor: `hsl(${hue}, 70%, 50%)`,
        backgroundPolygon: [
          {x: 0, y: 0},
          {x: width, y: 0},
          {x: width, y: height},
          {x: 0, y: height}
        ],
        polygons: []
      };
      this.canvasStates.set(canvas, state);
    }

    ctx.clearRect(0, 0, width, height);

    // Draw background polygon
    this.drawPolygon(ctx, state.backgroundPolygon, state.backgroundColor);

    // Generate or update polygons
    if (initialGeneration) {
      state.polygons = Array.from({ length: this.numPolygons }, () => ({
        points: this.generatePolygon(width, height),
        color: `hsl(${state.hue}, 70%, ${Math.floor(Math.random() * 60) + 20}%)`
      }));
    } else {
      state.polygons = state.polygons.map(polygon => ({
        points: this.morphPolygon(polygon.points, width, height),
        color: polygon.color
      }));
    }

    // Draw polygons
    state.polygons.forEach(polygon => {
      this.drawPolygon(ctx, polygon.points, polygon.color);
    });
  }

  generatePolygon(width, height) {
    const centerX = Math.random() * width;
    const centerY = Math.random() * height;
    const size = Math.random() * 0.2 + 0.05;

    return Array.from({ length: 3 }, () => ({
      x: centerX + (Math.random() - 0.5) * width * size,
      y: centerY + (Math.random() - 0.5) * height * size
    }));
  }

  morphPolygon(polygon, width, height) {
    const morphAmount = 0.02;
    return polygon.map(point => ({
      x: Math.max(0, Math.min(width, point.x + (Math.random() - 0.5) * width * morphAmount)),
      y: Math.max(0, Math.min(height, point.y + (Math.random() - 0.5) * height * morphAmount))
    }));
  }

  generateAllImages() {
    for (let canvas of this.canvases) {
      this.generateImage(canvas);
    }
  }

  startAnimation(canvas) {
    if (!this.canvasStates.get(canvas).animationId) {
      const animate = () => {
        this.generateImage(canvas, false);
        this.canvasStates.get(canvas).animationId = requestAnimationFrame(animate);
      };
      animate();
    }
  }

  stopAnimation(canvas) {
    const state = this.canvasStates.get(canvas);
    if (state && state.animationId) {
      cancelAnimationFrame(state.animationId);
      state.animationId = null;
    }
  }
}

// Usage
const lowPoly = new LowPolyGenerator('default-svg', 'canvas-parent', 50, 100);
lowPoly.generateAllImages();

// To regenerate images (e.g., on button click)
// function regenerateImages() {
//   lowPoly.generateAllImages();
// }