
jQuery(document).ready(function($) {
    $(document).on('click', '.gform_next_button', function(event) {
        // Log validation on Next button click
        console.log('Next button clicked. Validating fields...');

        let allValid = true;
        const currentPageFields = $(this).closest('form').find('.gform_page:visible input, .gform_page:visible textarea');

        currentPageFields.each(function() {
            if ($(this).prop('required') && $(this).val() === '') {
                console.warn('Field "' + $(this).attr('name') + '" is empty!');
                allValid = false;
            }
        });

        // Prevent moving to the next page if any field is invalid
        if (!allValid) {
            event.preventDefault();
            alert('Please fill in all required fields.');
        }
    });
});